import MainLogo from "../Logo/main_logo";
import NavBar from "../Navigation/NavBar";

function Header({openLoginModal}) {
    return (
        <div id="header-container">
            <MainLogo/>
            <div>
                <NavBar openLoginModal={openLoginModal}/>
            </div>
        </div>
    );
}

export default Header;