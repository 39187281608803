import React from 'react'
import { auth, logout, fbfsdb } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import default_photo from '../../assets/def_prof_pic.jpeg'
import { NavLink } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { doc, getDoc, deleteField, updateDoc } from '@firebase/firestore';

function NavBar({openLoginModal}) {
    const [userSignedIn, setUserSignedIn] = useState(null);
    const [user, loading] = useAuthState(auth);
    const [showModal, setShowModal] = useState('hide');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [optedIntoBlogPosts, setOptedIntoBlogPosts] = useState(false);
    const [optedIntoStories, setOptedIntoStories] = useState(false);

    useEffect(() => {
        const obtainUD = async () => {
            let userdoc = await getDoc(doc(fbfsdb, `users/${user?.uid}`));
            let userdata = userdoc.data();
            if (userdata['newBlogPosts']) {
                setOptedIntoBlogPosts(true);
            }
            else {
                setOptedIntoBlogPosts(false);
            }
            if (userdata['newStories']) {
                setOptedIntoStories(true);
            }
            else {
                setOptedIntoStories(false);
            }
        }

        if (user) {
            obtainUD().then().catch(err => console.log(err));
        }
    }, [user])

    useEffect(() => {
        let photo_url
        if (loading) return;
        if (user) {
            photo_url = user?.photoURL ? user?.photoURL : default_photo

            setUserSignedIn(
                <div className="dropdown">
                    <img alt='user' src={photo_url} className="dropbtn"/>
                    <div className="dropdown-content">
                        {/* <a href="/settings">Settings</a> */}
                        <div onClick={onOpen}>Settings</div>
                        <hr className='dropdownseparator'/>
                        <div onClick={logout}>Sign out</div>
                    </div>
                </div>
            )

        }
        else {
            photo_url = default_photo;
            setUserSignedIn(
                <div className="dropdown">
                    <img alt='user' src={photo_url} className="dropbtn"/>
                    <div className="dropdown-content">
                        <div onClick={((e) => {openLoginModal()})}>Sign in</div>
                    </div>
                </div>
            )
        }
    }, [user, loading, openLoginModal])

    const toggleBlogs = async () => {
        if (optedIntoBlogPosts) {
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                newBlogPosts: deleteField()
            })
            setOptedIntoBlogPosts(false);
        }
        else {
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                newBlogPosts: true
            })
            setOptedIntoBlogPosts(true);
        }
    }

    const toggleStories = async () => {
        if (optedIntoStories) {
            setOptedIntoStories(false);
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                newStories: deleteField()
            })
        }
        else {
            setOptedIntoStories(true);
            await updateDoc(doc(fbfsdb, `users/${user?.uid}`), {
                newStories: true
            })
        }
    }

  return (
    <div id="navbar">
        <div id="navbarfull">
            <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/">Home</NavLink>
            <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/learn">Learn</NavLink>
            <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/stories">Stories</NavLink>
            {/* <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/dictionary">Dictionary</NavLink> */}
            {/* <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/store">Store</NavLink> */}
        </div>
        {userSignedIn}
        <div onClick={() => {setShowModal('hide')}} id="navmodal" className={showModal}>
            <ul id="menu">
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/">Home</NavLink>
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/learn">Learn</NavLink>
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/stories">Stories</NavLink>
                {/* <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/dictionary">Dictionary</NavLink> */}
                {/* <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/store">Store</NavLink> */}
            </ul>
        </div>
        <div id="navigationmenu">
            <div onClick={() => {setShowModal('show')}} id="nav_options_menu" className='openmodal'>&#xe5d2;</div>
        </div>
        {isOpen &&
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className={'settingsmodalcontent'}>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody id='chakrasettings'>
                  {
                    <div style={{textAlign: 'center', padding: '0 0 20px 0'}}>
                        {/* show if user is subscribed to newBlogPosts or newStories */}
                        <div>
                            <label className={'numuknowupdates'}>
                                <input type={'checkbox'} checked={optedIntoBlogPosts} onChange={((e) => {toggleBlogs()})}/>
                                <span>Get updated about new blog posts</span>
                            </label>
                            <label className={'numuknowupdates'}>
                                <input type={'checkbox'} checked={optedIntoStories} onChange={((e) => {toggleStories()})}/>
                                <span>Get updated about new interlinear stories</span>
                            </label>
                        </div>
                    </div>
                  }
                </ModalBody>
                </ModalContent>
            </Modal>
        }
    </div>
  )
}

export default NavBar