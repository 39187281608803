import './App.css';
import Home from './components/Home/Home';
import Stories from './components/Stories/Stories';
import Learn from './components/Learn/Learn';
import Settings from './components/Settings/Settings';
import Store from './components/Store/Store';
import Dictionary from './components/Dictionary/Dictionary';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from './components/Header/Header';
import { useState, useEffect } from 'react';
import ReadBlog from './components/Learn/ReadBlog';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { auth, LoginOptions } from './services/firebase';
import { useAuthState } from "react-firebase-hooks/auth";


function App() {
  const [user, loading] = useAuthState(auth);
  const [blogPosts, setBlogPosts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    let wpsite = 'https://public-api.wordpress.com/wp/v2/sites/sereneanlinguist.wordpress.com/posts'
    // fetch data from wordpress site and set to blogPosts state
    // run an async function
    const getBlogPosts = async () => {
      // fetch data from wordpress site
      const response = await fetch(wpsite);
      // convert response to json
      const data = await response.json();
      // set blogPosts state
      setBlogPosts(data);
    }
    // call the async function
    getBlogPosts().then().catch(err => console.log(err));
  }, [])

  useEffect(() => {
    if (loading) {
        return
    }
    if (user?.uid) {
        onClose()
    }
    const numuKnowOpenedLocally = localStorage.getItem('openedLocally')
    if (!numuKnowOpenedLocally) {
        onOpen();
        localStorage.setItem('openedLocally', true)
    }
  }, [loading, user, onClose, onOpen])

  return (
    <div className="App">        
        <Router>
        <Header openLoginModal={onOpen} />
          <Routes>
            <Route path="/" element={<Home blogPosts={blogPosts} />} />
            <Route path='/learn' element={<Learn blogPosts={blogPosts} />} />
            <Route path='/learn/:slug' element={<ReadBlog />} />
            <Route path="/stories/*" element={<Stories />} />
            <Route path='/dictionary' element={<Dictionary/>} />
            <Route path="/settings" element={<Settings/>} />
            <Route path="/store" element={<Store/>} />
          </Routes>
        </Router>
        {isOpen &&
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className={'settingsmodalcontent'}>
                <ModalHeader>Pesa mu kemmana!</ModalHeader>
                <ModalCloseButton />
                <ModalBody id='chakramodalwelcome'>
                  {
                    <div style={{textAlign: 'center', padding: '0 0 20px 0'}}>
                        <div style={{fontSize: '16px', margin: 10 + 'px ' + 0 + 'px'}}>Subscribe to get new stories and blogs in your inbox.</div>
                        <LoginOptions />
                        <p className='optinnothanks' onClick={onClose}>No thanks</p>
                    </div>
                  }
                </ModalBody>
                </ModalContent>
            </Modal>
        }
    </div>
  );
}

export default App;
