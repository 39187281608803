import React from 'react'

function Dictionary() {
  // useEffect to fetch all words from the database
  // get pathnames from the url and display the selected word and its definition

  return (
    <div>
      <div className='welcome'>Dictionary</div>
      {/* first, side bar with a search feature which will contain all words in the dictionary */}
      {/* second, a main section which will contain the word and its definition */}

    </div>
  )
}

export default Dictionary