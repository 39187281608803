import StyledFirebaseAuth from "./StyledFirebaseAuth.tsx";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { doc, getFirestore, setDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAcMEM3Ebs9Jq_G2u4SJ0W5UkCS8W-Djkc",
    authDomain: "numu-know.firebaseapp.com",
    projectId: "numu-know",
    storageBucket: "numu-know.appspot.com",
    messagingSenderId: "842129533056",
    appId: "1:842129533056:web:7a55b88bb5394a1e435e65",
    measurementId: "G-PQK8CG3MLF"
  };

  const app = firebase.initializeApp(firebaseConfig);
  export const fbfsdb = getFirestore(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const firebaseUiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // this is a new user, so add them to the firestore users collection!
        if (authResult.additionalUserInfo.isNewUser) {
          setDoc(doc(fbfsdb, `users/${authResult.user.uid}`), {
            displayName: authResult.user.displayName,
            email: authResult.user.email,
            newBlogPosts: true,
            newStories: true
          }).then((data) => {
            // create klaviyo user
            let klaviyoUrl = `https://numu-know-node-server-aybatncapq-uc.a.run.app/userSubscribed`
            var ckurequestOptions = {
              method: 'GET',
              headers: {
                "uid": authResult.user.uid
              }
            }

            fetch(klaviyoUrl, ckurequestOptions)
          }).catch((err) => {console.log('err', err);})
        }
        return false;
      },
      uiShown: () => {
      },
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  };
  const logout = () => {
    signOut(auth);
  };
  const LoginOptions = () => {
    return (
      <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
    )
  }
  export {
    app,
    auth,
    signInWithGoogle,
    LoginOptions,
    logout,
  };