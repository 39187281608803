import React, { useState, useEffect, useCallback } from 'react'
import ChosenDoc from './ChosenDoc';
import DocsList from './DocsList';
import { auth, fbfsdb } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDoc, doc } from 'firebase/firestore';

function Stories() {
  const [nowloading, setNowLoading] = useState("loading");
  const [docLoading, setDocLoading] = useState('docloading')
  const [documentList, setDocumentList] = useState([]);
  const [draftsList, setDraftsList] = useState([]);
  const [docToSee, setDocToSee] = useState('');
  const [docContent, setDocContent] = useState([]);
  const [docIsSelected, setDocIsSelected] = useState('docnotselected');
  const [highlights, setHighlights] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user] = useAuthState(auth);
  const [storyStatus, setStoryStatus] = useState(null);
  const [audioVisible, setAudioVisible] = useState(false);

  const selectDoc = (chosendoc) => {
    window.history.replaceState(null, "", "/stories/" + encodeURI(chosendoc))
    setDocLoading('docloading')
    setDocToSee(chosendoc)
    get_data(chosendoc)
  }

  const get_data = useCallback(async (chosendoc) => {
    let documentpath = window.location.pathname.replace("/stories", "")
    documentpath = documentpath.replaceAll("/", "")
    let currchosendoc;
    if (documentpath.length > 0) {
      currchosendoc = decodeURIComponent(documentpath);
      setDocToSee(currchosendoc)
      setDocIsSelected('docisselected')
    }
    else {
      currchosendoc = 'A Bird Story'
      setDocToSee(currchosendoc)
      setDocIsSelected('docnotselected')
    }

    try {
      let allLiveFB = await getDoc(doc(fbfsdb, `allDocuments/live`));
      let allLive = allLiveFB.data();
      let allLiveDocuments = allLive['documents'];
      allLiveDocuments.sort(function(a, b) {
        return a.localeCompare(b);
      })
      setDocumentList(allLiveDocuments);

      if (allLiveDocuments.includes(chosendoc)) {
        let story = await getDoc(doc(fbfsdb, `documents/${chosendoc}`));
        let storyData = story.data();
        setStoryStatus('live');
        setDocContent(storyData['item']['data'])
      }

      let getUserData = async(chosendoc) => {
        let userDoc = await getDoc(doc(fbfsdb, `users/${user?.uid}`))
        let userFields = userDoc.data();
        if (userFields?.admin) {
            setIsAdmin(true)
            // get drafts and set them
          let allDraftsFB = await getDoc(doc(fbfsdb, `allDocuments/drafts`));
          let allDraftsFull = allDraftsFB.data();
          let allDrafts = allDraftsFull['documents']
          allDrafts.sort(function(a, b) {
              return a.localeCompare(b);
          })
          setDraftsList(allDrafts)
          if (allDrafts.includes(chosendoc)) {
            let story = await getDoc(doc(fbfsdb, `drafts/${chosendoc}`));
            let storyData = story.data();
            setStoryStatus('draft')
            setDocContent(storyData['data'])
          }
          setNowLoading('notloading');
          setDocLoading('docnotloading');
        }
        else {
          setIsAdmin(false)
          setNowLoading('notloading');
          setDocLoading('docnotloading');
        }
      }
  
      if (user) {
        getUserData(chosendoc).catch(console.error)
      }
      else {
        setNowLoading('notloading');
        setDocLoading('docnotloading');
      }
    }
    catch (err) {
      console.log(err)
      setNowLoading('notloading')
      setDocLoading('docnotloading')
    }
  }, [user])

  useEffect(() => {
    get_data(docToSee)
    let query = window.location.search;
    const urlParams = new URLSearchParams(query);
    if (!highlights) {
      if (urlParams.has('highlights')) {
          setHighlights(JSON.parse(urlParams.get('highlights')))
      }
    }
  }, [docToSee, highlights, user, get_data])

  return (
    <div id='documentpage' className={audioVisible ? 'audioVisible' : ''}>
      <div id='docviewer'>
        <DocsList isAdmin={isAdmin} setHighlights={setHighlights} docIsSelected={docIsSelected} selectDoc={selectDoc} getData={get_data} chosenDoc={docToSee} loading={nowloading} documentList={documentList} draftsList={draftsList} />
        <ChosenDoc isAdmin={isAdmin} user={user?.uid} highlights={highlights} docIsSelected={docIsSelected} setHighlights={setHighlights} getData={get_data} selectDoc={selectDoc} chosenDoc={docToSee} docContent={docContent} docLoading={docLoading} storyStatus={storyStatus} setAudioVisible={setAudioVisible}/>
      </div>
    </div>
  )
}

export default Stories