import React, { useEffect, useState} from 'react'
import ModalVideo from 'react-modal-video'
import "react-modal-video/scss/modal-video.scss";
import {
  Spinner
} from '@chakra-ui/react';
import { collection, getDocs } from 'firebase/firestore';
import { fbfsdb } from '../../services/firebase';
import { Link } from 'react-router-dom';

export default function Learn({blogPosts}) {
  const [learningMaterials, setLearningMaterials] = useState({
    youtube: [],
    blog: [],
    tiktok: [],
    instagram: []
  })
  const [isOpen, setOpen] = useState(false)
  const [selectedVid, setSelectedVid] = useState(null)
  const [loading, setLoading] = useState(true);

  const openYTModal = (vid_id) => {
    setSelectedVid(vid_id)
    setOpen(true)
  }

  useEffect(() => {
      document.title = `Numu Know - Learn`

      // get data directly from firebase
      const getLearningMaterialsYT = async() => {
        let allYTvids = await getDocs(collection(fbfsdb, `ytvids`));
        let ytvidsArr = []
        allYTvids.forEach((video) => {
          let video_data = video.data();
          let docData = {
            "vid_id": video.id,
            "vid_description": video_data['description'],
            "vid_title": video_data['title'],
            "vid_publishDate": video_data['publish_date'],
            "vid_image": video_data['image']
          }

          ytvidsArr.push(docData)
        });

        ytvidsArr.sort(function(a,b) {
          return new Date(b.publish_date) - new Date(a.publish_date);
        });

        let result = {
          'youtube': ytvidsArr
        }

        setLearningMaterials(result)
        setLoading(false)
      }

      getLearningMaterialsYT().then().catch(err => console.log(err));
    }, [])

  const renderBlogPost = (id) => {
  }
  
  
  return (
    <div id='learningmats'>
      <div id='blogsection'>
        <div className='welcome'>Blog Posts</div>
        {
          <div id='blogpostslist'>
          {blogPosts && blogPosts.map((item, index) => {
            if (item.tags.includes(759719916)) {
              return
            }
            return (
              <div key={index} className={'learnblog'} onClick={((e) => {renderBlogPost(item.id)})}>
                <Link to={`/learn/${item.slug}`}><h3>{item.title.rendered}</h3></Link>
              </div>
            )
          })}
          </div>
        }
      </div>
      <div id='tiktoksection'>
        <div className='welcome'>TikTok Videos</div>
        <iframe
          id='tiktokiframe'
          title="TikTok video"
          src={`https://www.tiktok.com/embed/@tiktalkpaiute`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div id='ytvidssection'>
        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={selectedVid} onClose={() => setOpen(false)}/>
        <div className='welcome'>Youtube Videos</div>
        {
          loading &&
          <div id='ytvids_loading'>
            <Spinner size='xl'/>
          </div>
        }
        {
          !loading &&
          <div id='youtubevids'>
            {learningMaterials['youtube'].map((item, index) => {
              return (
                <div key={index} className={'learnmat'}  onClick={(() => {openYTModal(item.vid_id)})}>
                  <img src={item.vid_image} alt={'youtube vid: ' + item.vid_id}/>
                  <h3>{item.vid_title}</h3>
                  <div className='learnmatdesc'>{item.vid_description}</div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </div>
  )
}
