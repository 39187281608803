import {
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow
} from '@chakra-ui/react';
import { IoIosArrowRoundBack } from 'react-icons/io'
import Creator from './Creator';
import { FaRegEdit } from 'react-icons/fa'
import { useDisclosure } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'
import { useState, useEffect } from 'react';
import { doc, setDoc, updateDoc, arrayRemove, arrayUnion, deleteDoc } from "firebase/firestore";
import { fbfsdb } from '../../services/firebase';
import NewAtt from './NewAtt';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { app } from '../../services/firebase';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// need to destructure props
function ChosenDoc({isAdmin, user, highlights, docIsSelected, setHighlights, getData, selectDoc, chosenDoc, docContent, docLoading, storyStatus, setAudioVisible}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editWordDetails, setEditWordDetails] = useState(null);
  const [sentenceIndex, setSentenceIndex] = useState(null);
  const [wordIndex, setWordIndex] = useState(null);
  const [editingCurrentWordObj, setEditingCurrentWordObj] = useState(null);
  const [editWordLoading, setEditWordLoading] = useState(false);
  const [newAttributes, setNewAttributes] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);

  const deleteAtt = (att) => {
    const newItems = {...editingCurrentWordObj};
    delete newItems[att];
    setEditingCurrentWordObj(newItems);
  },
    addAnAttribute = () => {
      // first, check that last attribute is completed
      if (newAttributes.length > 0) {
        let lastEl = newAttributes[newAttributes.length - 1]
        if (lastEl.attributeName.trim().length === 0) {
          return
        }
      }
      setNewAttributes(newAttributes => [...newAttributes, {attributeName: '', attributeValue: ''}])
    },
    capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    closeModal = async(e) => {
      e.preventDefault();
      // change 'Confirm changes' button to loader
      setEditWordLoading(true);

      // send editingCurrentWordObj to server at index (user user as uid header)
      let updatedDocContent = docContent
      updatedDocContent[sentenceIndex]['words'][wordIndex] = editingCurrentWordObj

      let updatedDoc 

      let source;
      if (storyStatus === 'draft') {
        source = 'drafts'
        updatedDoc = {
          data: updatedDocContent
        }
      }
      else {
        source = 'documents'
        updatedDoc = {
          item: {
            data: updatedDocContent
          }
        }
      }

      // update story at sentenceIndex + wordIndex
      await updateDoc(doc(fbfsdb, `${source}/${chosenDoc}`), updatedDoc)

      // change loader to 'Confirm changes'
      setEditWordLoading(false);
      onClose();
    },
    confirmDialog = (msg) => {
      return new Promise(function (resolve, reject) {
        let confirmed = window.confirm(msg);

        return confirmed ? resolve(true) : resolve(false);
      });
    },
    editWord = (word_obj, sindex, windex) => {
      setEditWordDetails(word_obj);
      setEditingCurrentWordObj(word_obj)
      setSentenceIndex(sindex);
      setWordIndex(windex);
      setNewAttributes([]);
      onOpen();
    },
    editWordAtts = (word_obj) => {
      let word_obj_keys = Object.keys(word_obj)
      let priority = [
        'word',
        'morphology',
        'gloss',
        'notes'
      ]
      let sorted_word_obj_keys = word_obj_keys.sort(function(a, b) {
        let orderA = priority.indexOf(a);
        let orderB = priority.indexOf(b);
        if (orderA !== -1 && orderB !== -1) {
          return orderA - orderB;
        } else if (orderA !== -1) {
          return -1;
        } else if (orderB !== -1) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });

      return (
        <div>
          {sorted_word_obj_keys.map((att, index) => {
            if (att.startsWith("variant")) {
              return false
            }
            if (att === 'word') {
              return (
                <label key={`editAtt${index}`}>
                  <div className='editAttLabel'>{capitalize(att)}</div>
                  <textarea disabled value={word_obj[att]} ></textarea>
                </label>
              )
            }
            return (
              <label key={`editAtt${index}`}>
                <div className='editAttLabelContainer'>
                  <div className='editAttLabel'>{capitalize(att)}</div>
                  <div className='editAttDelete' onClick={((e) => {
                    // remove att element
                    e.target.parentNode.parentNode.parentNode.removeChild(e.target.parentNode.parentNode)
                    // delete the current att
                    deleteAtt(att);
                  })}>Delete</div>
                </div>
                <textarea defaultValue={word_obj[att]} onChange={((e) => setTimeout(() => setEditingCurrentWordObj({
                  ...editingCurrentWordObj,
                  [att]: e.target.value
                }), 5))}></textarea>
              </label>
            )
          })}
        </div>
      )
    },
    getWordAtts = (word_obj) => {
      let word_obj_keys = Object.keys(word_obj)
      let priority = [
        'morphology',
        'gloss',
        'notes'
      ]
      let sorted_word_obj_keys = word_obj_keys.sort(function(a, b) {
        let orderA = priority.indexOf(a);
        let orderB = priority.indexOf(b);
        if (orderA !== -1 && orderB !== -1) {
          return orderA - orderB;
        } else if (orderA !== -1) {
          return -1;
        } else if (orderB !== -1) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });

      return (
        <div>
          {sorted_word_obj_keys.map((att, attIndex) => {
            if (att.startsWith("variant") || att === "word") {
              return false
            }
            return (
              <div className='wordattandval' key={`wordatt${attIndex}`}><span className='wordatt'>{capitalize(att)}</span><span>: </span><span>{word_obj[att]}</span></div>
            )
          })}
        </div>
      )
    },
    createNewDoc = () => {
      return (<Creator setHighlights={setHighlights} selectDoc={selectDoc} confirmDialog={confirmDialog} />)
    },
    toggleStoryStatus = async () => {
      // first, confirm with admin user that they want to toggle the story status
      if (storyStatus === 'draft') {
        confirmDialog('Are you sure you want to publish this draft?')
        .then(async (resp) => {
          if (resp) {
            console.log('admin confirmed they want to publish this draft')

            // check that admin wants to share this story with all users via webhook to Klaviyo
            confirmDialog('Do you want to share this story with users who have subscribed to new stories?')
            .then(async (announce_resp) => {
              if (announce_resp) {
                // pass story_title and story_content to server
                // server will send webhook to Klaviyo
                let story_title = chosenDoc
                let story_content = docContent
                let story_data = {
                  story_title: story_title,
                  story_content: story_content
                }
                let shareOptions = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'uid': user
                  },
                  body: JSON.stringify(story_data)
                }
                let fetch_url = 'https://numu-know-node-server-aybatncapq-uc.a.run.app/newStory';
                await fetch(fetch_url, shareOptions);
                console.log('admin shared this story with all users')
              }
              else {
                console.log('admin did not share this story with all users')
              }

              // user wants to publish a draft
              // add document id to allDocuments/live
              await updateDoc(doc(fbfsdb, `allDocuments/live`), {
                documents: arrayUnion(chosenDoc)
              })
              
              // remove document id from allDocuments/drafts
              await updateDoc(doc(fbfsdb, `allDocuments/drafts`), {
                documents: arrayRemove(chosenDoc)
              })

              // copy document into /documents/{document}
              await setDoc(doc(fbfsdb, `documents/${chosenDoc}`), {
                item: {
                  data: docContent
                }
              })

              // delete /drafts/{document}
              await deleteDoc(doc(fbfsdb, `drafts/${chosenDoc}`))

              // re-render?
              getData(chosenDoc);
            }).catch(announce_err => {
              console.error(announce_err);
            })
          }
          else {
            console.log('admin cancelled publishing draft')
          }
        }).catch(err => {
          console.error(err);
        })
      }
      else {
        confirmDialog('Are you sure you want to unpublish this story?')
        .then(async (resp) => {
          if (resp) {
            // user wants to unpublish a live story
              // add document id to allDocuments/drafts
              await updateDoc(doc(fbfsdb, `allDocuments/drafts`), {
                documents: arrayUnion(chosenDoc)
              })

              // remove document id from allDocuments/live
              await updateDoc(doc(fbfsdb, `allDocuments/live`), {
                documents: arrayRemove(chosenDoc)
              })

              // copy document into /drafts/{document}
              await setDoc(doc(fbfsdb, `drafts/${chosenDoc}`), {
                data: docContent
              })

              // delete /documents/{document}
              await deleteDoc(doc(fbfsdb, `documents/${chosenDoc}`))

              // re-render?
              getData(chosenDoc);
          }
        }).catch(err => {
          console.error(err);
        })
      }
    },
    renderADoc = () => {
      return (
        <>
          {
            docContent.length > 0 &&
            <div>
              {
                isAdmin &&
                <div id='doctitle'><span>{chosenDoc}</span><button className='draftLabel' onClick={((e) => toggleStoryStatus())}>{storyStatus}</button></div>
              }
              {
                !isAdmin &&
                <div id='doctitle'>{chosenDoc}</div>
              }
              {
                audioUrl &&
                <div id='storyAudio'>
                  <AudioPlayer
                    autoPlay
                    src={audioUrl}
                    onPlay={e => console.log("onPlay")}
                    // other props here
                  />
                </div>
              }
              {
                docContent.map((row, index) => {
                  if (row['block']) {
                    return (
                      <div className="informational" key={index}>{row['block'].replace("```", "")}</div>
                    )
                  }
                  else {
                    let wordSet = row['words']
                    return (
                      <div className='storyline' key={index}>
                        <div className='story_original'>
                          {
                            wordSet.map((ind_word, wordindex) => {
                              if (ind_word['word']) {
                                return (
                                  <Popover boundary={document.querySelector('#documentContent')} key={index.toString() + wordindex.toString()} trigger="hover" variant="responsive" >
                                    {({ isOpen, onClose }) => (
                                      <>
                                        <PopoverTrigger>
                                          <span className={'storyword ' + (isOpen ? 'open ' : '') + ((highlights[index] ? highlights[index].includes(wordindex) : false) ? 'highlighted' : '')}>{ind_word['word']}</span>
                                        </PopoverTrigger>
                                        <PopoverContent className='numuknowpopup' boxShadow="0px 0px 13px 6px rgba(120,130,144,1)">
                                          <PopoverArrow />
                                          <PopoverBody className='numuknowpopupcontent' maxHeight={'300px'} >
                                            {
                                              isAdmin &&
                                              <FaRegEdit className='adminEditWordAtts' onClick={((e) => editWord(ind_word, index, wordindex))}  />
                                            }
                                            {getWordAtts(ind_word)}
                                          </PopoverBody>
                                        </PopoverContent>
                                      </>
                                    )}
                                  </Popover>
                                )
                              }
                              else if (ind_word['punctuation']) {
                                if (ind_word['punctuation'] === "“") {
                                  if (wordindex === 0) {
                                    return (
                                      <span className='rfpunctfirst' key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                                    )
                                  }
                                  else {
                                    return (
                                      <span className='rfpunct' key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                                    )
                                  }
                                }
                                return (
                                  <span key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                                )
                              }
                              return false
                            })
                          }
                        </div>
                        <div className='story_translation'>{row['translation']}</div>
                      </div>
                    )
                  }
                })
              }
              {
                isOpen &&
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Edit Word</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <form id='editWordForm' onSubmit={((e) => closeModal(e))}>
                        {editWordAtts(editWordDetails)}
                        <>{newAttributes.map((newAtt, index) => {
                          return (<NewAtt key={index} editingCurrentWordObj={editingCurrentWordObj} setEditingCurrentWordObj={setEditingCurrentWordObj} deleteAtt={deleteAtt} currentIndex={index} newAtt={newAtt} newAttributes={newAttributes} setNewAttributes={setNewAttributes}/>)
                        })}</>
                        <div id='editWordBtnsContainer'>
                          <Button backgroundColor='#788290' color={'white'} onClick={((e) => {addAnAttribute()})}>
                            Add an attribute
                          </Button>
                          <Button isLoading={editWordLoading} backgroundColor='#788290' color={'white'} type='submit'>
                            Confirm changes
                          </Button>
                        </div>
                      </form>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              }
            </div>
          }
          {
            docContent.length === 0 &&
            <div>
              <div id='doctitle'>Uh-Oh!</div>
              <p id='docnotfound'>We couldn't find that story.</p>
            </div>
          }
        </>
      )
    }


    useEffect(() => {
      document.title = `Numu Know - ${chosenDoc}`
      const storage = getStorage(app);
      console.log(`checking for audio at stories/${chosenDoc}/${chosenDoc}.wav`)
      const folderRef = ref(storage, `stories/${chosenDoc}`);
      let audioRef = false;
      listAll(folderRef).then((res) => {
        if (res.items.length > 0) {
          audioRef = ref(storage, `stories/${chosenDoc}/${chosenDoc}.wav`)
        }
        
        if (audioRef) {
          getDownloadURL(audioRef).then(url => {
            setAudioUrl(url);
            setAudioVisible(true);
          }).catch((err) => {
            setAudioUrl(false);
            setAudioVisible(false);
          })
        }
        else {
          setAudioUrl(false);
          setAudioVisible(false);
        }
      });      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenDoc]);

    useEffect(() => {
      if (docLoading !== 'docloading') {
        document.querySelector('#documentContent').scrollTo({ top: 0, behavior: 'smooth' });;
      }
    }, [docLoading])
    


  return (
    <div id='chosen-doc' className={docIsSelected}>
      <IoIosArrowRoundBack onClick={() => { selectDoc("") }} id='backtodocs' className={docIsSelected} />
      <div id='documentloader' className={docLoading}><Spinner size='xl' /></div>
      <div id="documentContent" className={docLoading}>
        {chosenDoc === "createnewstory" ? createNewDoc() : renderADoc()}
      </div>
    </div>
  )
}

export default ChosenDoc