import { Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import { IoIosSearch, IoMdClose } from 'react-icons/io'
import Search from '../Search/Search';

function DocsList(props) {
    const [fullscreen, setFullScreen] = useState('')
    const [showClearSearch, setShowClearSearch] = useState('hidden')
    const [searchTerm, setSearchTerm] = useState('')

    const createStory = () => {
        props.setHighlights(false)
        props.selectDoc('createnewstory')
    }

    const changedict = (e, doc) => {
        props.setHighlights(false)
        props.selectDoc(doc)
    }
    const numuknowsearch = async (event) => {
        event.preventDefault();
        blurAll();
        setFullScreen('fullscreen');
    }

    const blurAll = () => {
        var tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
    }

    return (
        <div id='documentsearchcontainer' className={props.docIsSelected}>
            {fullscreen && <Search setHighlights={props.setHighlights} changedict={changedict} fullscreen={fullscreen} setFullScreen={setFullScreen} searchTerm={searchTerm}/>}
            <form id='numu-search-box' onSubmit={numuknowsearch}>
                {/* search box here will query algolia */}
                {/* algolia will respond and we'll highlight responses manually */}
                <input 
                    id='numu-search-input'
                    placeholder='Search'
                    autoComplete="off"
                    onChange={function(e) {
                        if (e.target.value === "") {
                          setShowClearSearch('hidden')
                        }
                        else {
                          setShowClearSearch('')
                        }
                        setSearchTerm(e.target.value)
                      }}
                />
                <IoMdClose
                    onClick={function(e) {
                        setShowClearSearch('hidden')
                        document.querySelector("#numu-search-input").value = ''
                        setSearchTerm('')
                    }}
                    id='clearsearchinput'
                    className={showClearSearch}
                />
                <IoIosSearch id='numu-search-icon'/>
            </form>
            <div id='documentscontainer'>
                {
                    props.documentList.length === 0 &&
                    <div id='documentloader' className={props.loading}><Spinner size='xl'/></div>
                }
                <div id='documentslist'>
                {props.isAdmin && props.loading === 'notloading' ? <div
                                id='createnewstory'
                                onClick={((e) => createStory())}
                                className={(props.chosenDoc === 'createnewstory' ? 'selected': "")}>
                                    Create a new story
                            </div>: <></>}
                {
                    props.draftsList.length > 0 &&
                    props.draftsList.map((doc, index) => {
                        return(
                            <div onClick={((e) => changedict(e, doc))} className={"ind_doc " + (props.chosenDoc === doc ? 'selected': "")} key={index}><span>{doc}</span><span className='draftLabel'>{'(draft)'}</span></div>
                        )
                    })
                }
                {
                    props.documentList.map((doc, index) => {
                        return(
                            <div onClick={((e) => changedict(e, doc))} className={"ind_doc " + (props.chosenDoc === doc ? 'selected': "")} key={index}>{doc}</div>
                        )  
                    }
                )}
                </div>
            </div>
        </div>
    )
}

export default DocsList