import React, { useEffect, useState } from 'react'

function SearchResults(props) {
    const [tMatches, setTMatches] = useState(false)
    const [sMatches, setSMatches] = useState(false)
    const [bMatches, setBMatches] = useState(false)


    let result = props.result;
    let c_doc_translation_matches = []
    let c_doc_sentence_matches = []
    let c_doc_block_matches = []
    let story_results = {}
    
    let highlighted_array = result['_highlightResult']['item']['data']

    highlighted_array.map((sentence, sindex) => {
        if ('block' in sentence) {
            if (sentence['block']['matchedWords'].length > 0) {
                c_doc_block_matches.push(sentence['block']['value'].replaceAll("<em>", "<span class='matched_item'>").replaceAll("</em>", "</span>"))
            }
        }
        else {
            if (sentence['translation']['matchedWords'].length > 0) {
                c_doc_translation_matches.push(sentence['translation']['value'].replaceAll("<em>", "<span class='matched_item'>").replaceAll("</em>", "</span>"))
            }

            story_results[sindex] = []

            let words_of_sentence = sentence['words']
            let matched_a_word = false
            let matched_sentence = ""
            words_of_sentence.map((current_word, windex) => {
                let word_val = ""
                for (let key in current_word) {
                    if (current_word[key]['matchedWords'].length > 0) {
                        // render the sentence
                        story_results[sindex].push(windex)
                        matched_a_word = true
                        if ('word' in current_word) {
                            word_val = " <span class='matched_item'>" + current_word['word']['value'].replaceAll("<em>", "").replaceAll("</em>", "") + "</span>"
                        }
                        else {
                            word_val = "<span class='matched_item'>" + current_word['punctuation']['value'] + "</span>"
                        }
                    }
                }
                if (word_val === "") {
                    if ('word' in current_word) {
                        word_val = " " + current_word['word']['value']
                    }
                    else {
                        word_val = current_word['punctuation']['value']
                    }
                }
                matched_sentence = matched_sentence + word_val
                return true
            })
            if (matched_a_word === true) {
                c_doc_sentence_matches.push(matched_sentence)
            }
        }
        return true
    })

    let final_results = {}
    for (let key in story_results) {
        if (story_results[key].length > 0) {
            final_results[key] = story_results[key]
        }
    }
    
    let docName = result['objectID'].replace("documents/", "")
    // add final_results to setFinalResults prop obj

    let final_results_string = JSON.stringify(final_results)
    let encoded_final_results = encodeURIComponent(final_results_string)
    

    useEffect(() => {
        if (c_doc_translation_matches.length > 0) {
            setTMatches(true)
        }
        if (c_doc_block_matches.length > 0) {
            setBMatches(true)
        }
        if (c_doc_sentence_matches.length > 0) {
            setSMatches(true)
        }
    }, [c_doc_translation_matches.length, c_doc_block_matches.length, c_doc_sentence_matches.length])

    return (
        <div className='doc_card'>
            <a href={'?highlights=' + encoded_final_results}>
                <h1 className='res_title'>{docName}</h1>
                {
                    tMatches &&
                        <div className='t_match_card'>
                            <b>Translation Matches</b>
                            {c_doc_translation_matches.map((match, tindex) => {
                                return (<div key={'tmatch' + tindex} dangerouslySetInnerHTML={{__html: match}}></div>)
                            })}
                        </div>
                }
                {
                    sMatches &&
                        <div className='s_match_card'>
                            <b>Sentence Matches</b>
                            {c_doc_sentence_matches.map((match, sindex) => {
                                return (<div key={'smatch' + sindex} dangerouslySetInnerHTML={{__html: match}}></div>)
                            })}
                        </div>
                }
                {
                    bMatches &&
                        <div className='b_match_card'>
                            <b>Block Matches</b>
                            {c_doc_block_matches.map((match, bindex) => {
                                return (<div key={'bmatch' + bindex} dangerouslySetInnerHTML={{__html: match}}></div>)
                            })}
                        </div>
                }
            </a>
        </div>
    )
}

export default SearchResults