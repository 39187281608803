import React, { useEffect } from 'react'
import josue_pic from '../../assets/josue_wrpt_shirt.jpeg'
import welcome from '../../assets/welcome.json'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow
} from '@chakra-ui/react';

function Home({blogPosts}) {
    // this home component should render a list of recent activities.
    // When loaded, 
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  const getWordAtts = (word_obj) => {
      let word_obj_keys = Object.keys(word_obj)
      let priority = [
          'morphology',
          'gloss',
          'notes'
      ]
      let sorted_word_obj_keys = word_obj_keys.sort((a,b) => {
          const firstIndex = priority.indexOf(a);
          const secondIndex = priority.indexOf(b);
          return (
              (firstIndex === -1 ? 5 : firstIndex) -
              (secondIndex === -1 ? 5 : secondIndex)
          )
      })

      return (
          <div>
              {sorted_word_obj_keys.map((att, index) => {
                  if (att.startsWith("variant") || att === "word") {
                      return false
                  }
                  return (
                      <div className='wordattandval' key={index}><span className='wordatt'>{capitalize(att)}</span><span>: </span><span>{word_obj[att]}</span></div>
                  )
              })}
          </div>
      )
  }

  useEffect(() => {
    document.title = `Numu Know`
  }, [])
  

  return (
    <div id='welcomedocumentpage'>
      <div className='welcome'>Pesa mu kemmana!</div>
      <div id='welcome-block'>
        <img id='welcome-block-img' src={josue_pic} alt={'josue with walker river paiute tribe shirt'} />
        <div id='welcome-block-txt'>
          {
            welcome.data.map((row, index) => {
                    if (row['block']) {
                        return (
                            <div className="informational" key={index}>{row['block'].replace("```", "")}</div>
                        )
                    }
                    else {
                        let wordSet = row['words']
                        return (
                            <div className='storyline' key={index}>
                                <div className='story_original'>
                                    {
                                        wordSet.map((ind_word, wordindex) => {
                                            if (ind_word['word']) {
                                                return (
                                                    <Popover key={index.toString() + wordindex.toString()} trigger="hover" >
                                                        {({ isOpen, onClose }) => (
                                                            <>
                                                                <PopoverTrigger>
                                                                    <span className={'storyword ' + (isOpen ? 'open': '')}>{ind_word['word']}</span>
                                                                </PopoverTrigger>
                                                                <PopoverContent boxShadow="0px 0px 13px 6px rgba(120,130,144,1)">
                                                                    <PopoverArrow />
                                                                    <PopoverBody>
                                                                        {getWordAtts(ind_word)}
                                                                    </PopoverBody>
                                                                </PopoverContent>
                                                            </>
                                                        )}
                                                    </Popover>
                                                )
                                            }
                                            else if (ind_word['punctuation']) {
                                                return (
                                                    <span key={index.toString() + wordindex.toString()}>{ind_word['punctuation']}</span>
                                                )
                                            }
                                            return false
                                        })
                                    }
                                </div>
                                <div className='story_translation'>{row['translation']}</div>
                            </div>
                        )
                    }
                }
            )
          }
        </div>,
      </div>
      <hr className='divider'></hr>

      {
        blogPosts.filter(post => post.id === 62).map((post, index) => {
            return (
                <div key={`blogpost${index}`}>
                    <div className='welcome'>{post.title.rendered}</div>
                    <div key={index} className='welcomeabout'>
                        <div dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>
                    </div>
                </div>
            )
            }
        )
      }
      <hr className='divider'></hr>
      <div className='designedby'>The Numu-Know website was designed and developed by Andrew Nelson.</div>
    </div>
  )
}

export default Home