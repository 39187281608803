import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

function ReadBlog() {
    const { slug } = useParams();
    const [post, setPost] = useState({});
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const fetchPost = async () => {
        const response = await fetch(`https://public-api.wordpress.com/wp/v2/sites/sereneanlinguist.wordpress.com/posts?slug=${slug}`);
        const data = await response.json();
        setPost(data[0]);
        setIsLoading(false);
      };
      fetchPost();
    }, [slug]);
  
    return (
      <>
        {isLoading ? (
            <div id='loadingblogcontainer'>
                <Spinner size='xl'/>
            </div>
        ) : (
          <div id='blogcontainer'>
            <h1>{post.title.rendered}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </div>
        )}
      </>
    );
  };

export default ReadBlog