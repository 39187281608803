import React, { useState } from 'react';
import { auth, fbfsdb } from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Spinner
} from '@chakra-ui/react';
import { doc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';

function Creator(props) {
    const [currentStory, setCurrentStory] = useState([])
    const [confirming, setConfirming] = useState(false)
    const [title, setTitle] = useState('');
    const [user] = useAuthState(auth);
    const [submittingLoader, setSubmittingLoader] = useState('')
    const [fixTitle, setFixTitle] = useState('')

    const addBlock = () => {
        let newfield = { rowContent: '', type: 'block' }
        return newfield
    }

    const addLine = () => {
        let newfield = { rowContent: '', type: 'line', lineTranslation: ''}
        return newfield
    }

    const updateStory = (e, el) => {
        e.preventDefault()
        switch (el) {
            case 'block':
                setCurrentStory(currentStory => [...currentStory, addBlock()]);
                break;
            case 'line':
                setCurrentStory(currentStory => [...currentStory, addLine()]);
                break;
            default:
                break;
        }
    }

    const removeFields = (e, index) => {
        e.preventDefault();
        let a = document.querySelectorAll(".createstorytextarea")
        // test blocks and lines
        Array.from(a).map((ta, index) => {
            setFixTitle('')
            ta.parentNode.parentNode.classList.remove("fixThis")
        })
        let data = [...currentStory];
        data.splice(index, 1)
        setCurrentStory(data)
    }


    const handleFormChange = (index, event) => {
        let data = [...currentStory];
        data[index][event.target.name] = event.target.value;
        setCurrentStory(data);
    }

    const handleTranslationChange = (index, event) => {
        let data = [...currentStory];
        data[index][event.target.name] = event.target.value;
        setCurrentStory(data);
    }

    const goToConfirmChanges = (e) => {
        e.preventDefault();
        let needFixes = false;

        // test title
        if (document.querySelector("#creatortitle").value.trim().length === 0) {
            setFixTitle('fixTitle')
            needFixes = true;
        }

        let a = document.querySelectorAll(".createstorytextarea")
        // test blocks and lines
        Array.from(a).map((ta, index) => {
            if (ta.value.trim().length === 0) {
                ta.parentNode.parentNode.classList.add("fixThis")
                needFixes = true;
            }
            else {
                ta.parentNode.parentNode.classList.remove("fixThis")
            }
        })

        // test each block and line
        if (!needFixes) {
            setConfirming(true)
        }
    }

    const updateTitle = (e) => {
        setTitle(e.target.value)
    }

    const submitStory = async(e) => {
        e.preventDefault();
        setSubmittingLoader('loading')
        let sentences = []

        // example of currentStory:
        // [
        //     {
        //         "rowContent": "This is a description of this story.",
        //         "type": "block"
        //     },
        //     {
        //         "rowContent": "This is line 1.",
        //         "type": "line",
        //         "lineTranslation": "Translation 1."
        //     },
        //     {
        //         "rowContent": "This is line 2.",
        //         "type": "line",
        //         "lineTranslation": "Translation 2."
        //     },
        //     {
        //         "rowContent": "This is line 3.",
        //         "type": "line",
        //         "lineTranslation": "Translation 3."
        //     }
        // ]


        currentStory.map((line, index) => {
            let sentenceObj = {};
            if (line['type'] === "block") {
                sentenceObj['block'] = "```" + line['rowContent']
                sentences.push(sentenceObj)
            }
            else if (line['type'] === "line") {
                let setOfWords = [];
                sentenceObj["translation"] = line['lineTranslation'];
                line['rowContent'].split(" ").map((word, w_index) => {
                    let individualWordObj = {}
                    let periodObj = false
                    let endQuote = false

                    individualWordObj['word'] = word

                    if (individualWordObj['word'].includes("“")) {
                        let punctObj = {}
                        individualWordObj['word'] = individualWordObj['word'].replace("“", "")
                        punctObj["punctuation"] = "“"
                        setOfWords.push(punctObj);
                    }
                    
                    if (individualWordObj['word'].includes(".")) {
                        periodObj = {}
                        individualWordObj['word'] = individualWordObj['word'].replace(".", "")
                        periodObj["punctuation"] = "."
                    }

                    if (individualWordObj['word'].includes("?")) {
                        periodObj = {}
                        individualWordObj['word'] = individualWordObj['word'].replace("?", "")
                        periodObj["punctuation"] = "?"
                    }

                    if (individualWordObj['word'].includes("!")) {
                        periodObj = {}
                        individualWordObj['word'] = individualWordObj['word'].replace("!", "")
                        periodObj["punctuation"] = "!"
                    }
                    
                    if (individualWordObj['word'].includes(",")) {
                        periodObj = {}
                        individualWordObj['word'] = individualWordObj['word'].replace(",", "")
                        periodObj["punctuation"] = ","
                    }

                    if (individualWordObj['word'].includes("”")) {
                        endQuote = {}
                        individualWordObj['word'] = individualWordObj['word'].replace("”", "")
                        endQuote["punctuation"] = "”"
                    }
                    setOfWords.push(individualWordObj)
                    if (periodObj) {
                        setOfWords.push(periodObj)
                    }
                    if (endQuote) {
                        setOfWords.push(endQuote)
                    }
                })
                sentenceObj["words"] = setOfWords
                sentences.push(sentenceObj)
            }
        })
        console.log(sentences)

        let send = {
            'data': sentences,
            'author': 'Natchez',
            'defaultVar': "McDermitt (Wycliffe)"
        }

        await setDoc(doc(fbfsdb, `drafts/${title}`), send)
        await updateDoc(doc(fbfsdb, `allDocuments/drafts`), {
            documents: arrayUnion(title)
        })
        setSubmittingLoader('')

        props.setHighlights(false)
        props.selectDoc(title)
    }

  return (
    <div id='creatorform'>
        <div>
            <form onSubmit={(e) => submitStory(e)}>
                {confirming ? <div id='doctitle'>{title}</div>: <div id='creatortitlecontainer'><input autoComplete='off' id='creatortitle' className={fixTitle} placeholder='Title' onChange={(e) => updateTitle(e)}/></div>}
                <div id='newstorycontent'>
                    {currentStory.map((item, index) => {
                        console.log(item, index)
                        return (
                            <div key={'storyitem' + index}>
                            {
                                !confirming &&
                                <div id={'storyitem' + index} className={'createstoryitemrow'}>
                                        <div className='storyitemcontainer'>
                                                <textarea 
                                                    name='rowContent'
                                                    value={item.rowContent}
                                                    className={"createstorytextarea"}
                                                    placeholder={item.type}
                                                    onChange={event => handleFormChange(index, event)}></textarea>
                                                    {item.type === "line" &&
                                                        <textarea
                                                            name='lineTranslation'
                                                            value={item.lineTranslation}
                                                            className={"lineTranslation createstorytextarea"}
                                                            placeholder={"line translation"}
                                                            onChange={event => handleTranslationChange(index, event)}></textarea>
                                                    }
                                        </div>
                                    
                                    <button className={"createstorydelete"} onClick={(e) => removeFields(e, index)}>&times;</button>
                                </div>
                            }
                            {
                                confirming &&
                                item.rowContent !== "" &&
                                <div className='storyitemcontainer'>
                                    {
                                        item.type === "block" &&
                                        <div className="informational">{item.rowContent}</div>
                                    }
                                    {
                                        item.type === "line" &&
                                        <div>
                                            <div className='story_original'>{
                                                item.rowContent
                                            }</div>
                                            <div className='story_translation'>{item.lineTranslation}</div>
                                        </div>
                                    }
                                </div>
                            }
                            </div>
                        )
                    })}
                </div>
                {!confirming && <><button onClick={((e) => updateStory(e, 'block'))} className='creatorbtn'>Add a block</button>
                <button onClick={((e) => updateStory(e, 'line'))} className='creatorbtn'>Add a line</button></>}
                
                {confirming ? <div id='submitcreationcontainer' className={submittingLoader}><button id='submitcreation' type='submit'>Submit</button></div> : <div id='goToConfirmContainer'><button id='goToConfirm' onClick={(e) => goToConfirmChanges(e)}>Next</button></div>}
                {<div id='submittingLoader' className={submittingLoader}><button id='loadingspinnerbtn' disabled><Spinner/></button></div>}
            </form>
        </div>
    </div>
  )
}

export default Creator